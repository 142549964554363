import React from "react";
import profilePic from "../images/profile-pic.jpg";
import { rhythm } from "../utils/typography";

class Bio extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          marginBottom: rhythm(2),
        }}
      >
        <img
          src={profilePic}
          alt={`Jon Gautsch`}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            width: rhythm(2),
            height: rhythm(2),
            borderRadius: "50%",
          }}
        />
        <p style={{ maxWidth: 310 }}>
          Personal blog by{" "}
          <a href="https://mobile.twitter.com/jongautsch">Jon Gautsch</a>.
        </p>
      </div>
    );
  }
}

export default Bio;
